import Home from './pages/Home';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap/dist/js/bootstrap.min.js";


function App() {
  return (
    <>
    <Home/>

    </>
  );
}

export default App;
